.container {
  display: block;
  margin-top: 2%;
  background-color: #1e0f2a;
  /* margin-top : 5%;
    margin-bottom: 5%; */
}
.title {
  color: #e5e5e5;
  padding-right: 20%;
  padding-left: 20%;
  padding-top: 5%;
  font-family: "Inria Serif";
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 7vw;
  letter-spacing: 0em;
  text-align: center;
}
.content {
  color: #e5e5e5;
  text-align: center;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 10%;

  font-family: "Inria Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.8vw;
  letter-spacing: 0em;
  text-align: justify;
}
@media (max-width: 720px) {
  .container {
    margin-top: 0;
  }
  .title {
    color: #e5e5e5;
    text-align: center;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 5%;

    font-size: 4.5vw;
    line-height: 6vw;
  }
  .content {
    font-size: 4vw;
    line-height: 6vw;
  }
}
