.container {
  display: block;
  padding-top: 5%;

  width: 100%;
  height: auto;

  background: url("../../assets/Office/office-dark.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 720px) {
  .container {
    display: block;
  }
}
