.carousel {
  background: #ffffff;
  margin-top: 1%;
}
.slider {
  display: block;
  text-align: center;
  border: 1vw solid #e2c033;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0.1vw;
  padding-top: 3%;
}
.slide {
  margin-bottom: 0.5%;
}
.slide p {
  padding-top: 4%;
  padding-right: 17%;
  padding-left: 17%;
  font-family: "Inria Sans";
  font-size: 1.65vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: center;
}
.slide h1 {
  padding-top: 2%;
  padding-right: 17%;
  padding-left: 17%;
  font-family: "Inria Sans";
  font-size: 1.65vw;
  font-style: normal;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: center;
}
#imageDesktop {
  height: 15vw;
}
#imageMobile {
  display: none;
}
.dotGroup {
  text-align: center;
  margin-bottom: -2%;
  margin-top: -2%;
}
.dotGroup button {
  border: 0;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 1%;
  background: #c6c1c1;

  padding-top: 0.6%;
  padding-bottom: 0.6%;
  padding-right: 0.6%;
  padding-left: 0.6%;

  border-radius: 10vw;
}
.dotGroup button:disabled {
  background: #e2c033;
}

@media (max-width: 720px) {
  .slide p {
    font-size: 3vw;
    font-weight: 400;
    line-height: 5.6vw;
    padding-right: 5%;
    padding-left: 5%;
  }
  .slider {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .dotGroup {
    margin-top: 3%;
  }
  .dotGroup button {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-right: 1.5%;
    padding-left: 1.5%;
  }
  #imageDesktop {
    display: none;
  }
  #imageMobile {
    display: inline;
    height: 30vw;
  }
}
