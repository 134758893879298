.card {
  height: 97%;

  justify-content: center;
  align-items: center;

  background: #dddbdf;
  border-radius: 8px;
  padding-top: 1%;
  padding-bottom: 5%;
  margin-right: 5%;
  margin-top: 3%;
}

.card h1 {
  font-family: "Inria Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  border-radius: 10px;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.card p {
  font-family: "Inria Sans";
  font-size: 18px;
  font-style: normal;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 400;
  margin-left: 10%;
  margin-right: 10%;
}

.card ul {
  font-family: "Inria Sans";
  font-size: 18px;
  font-style: normal;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5%;
  font-weight: 400;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.card li {
  padding-top: 2%;
}

.card h2 {
  font-family: "Inria Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;
}

@media (max-width: 960px) {
  .card h1 {
    font-size: 25px;
  }
  .card h2 {
    font-size: 20px;
  }
}

@media (max-width: 720px) {
  .card {
    display: none;
  }
}
