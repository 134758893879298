.container {
  margin-top: 5%;
  margin-bottom: 5%;
}

.title h1 {
  font-family: "Inria Serif";
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 3%;
}
#titleMobile {
  display: none;
}

@media (max-width: 720px) {
  #titleDesktop {
    display: none;
  }
  #titleMobile {
    display: block;
  }

  .title h1 {
    font-size: 6.5vw;
    margin-top: 13%;
    margin-bottom: 8%;
  }
}
