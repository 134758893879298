.container {
  background: rgba(58, 36, 74, 0.61);
  border: 3px solid #1e0f2a;
  box-sizing: border-box;
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding-top: 5%;
  padding-bottom: 5%;
  height: 100%;
}

.container h1 {
  color: #ffffff;
  font-family: "Red Hat Text";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: center;
}
.container p {
  color: #a7a5a9;

  font-family: "Inria Sans";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 4%;
  padding-right: 2%;
  padding-top: 5%;
  padding-bottom: 2%;
}

@media (max-width: 720px) {
  .container {
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 3%;
    margin-top: 3%;
  }
  .container h1 {
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 7vw;
    text-align: left;
    padding-left: 5%;
    padding-right: 35%;
  }
  .container p {
    font-size: 3.4vw;
    line-height: 5vw;
    padding-left: 5%;
    padding-right: 5%;
  }
}
