.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3%;
  margin-bottom: -5%;
}
.input {
  border: 0;
  outline: none;
  color: #706b6b;
  font-size: 1vw;
  background: #d3cdcd;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 2%;
  width: 100%;
  margin-bottom: 3%;
}
.formRight {
  margin-left: 8%;
  margin-right: 3%;
}
.form button {
  margin-top: 2%;
  width: 60%;
  background: #1e0f2a;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  font-family: "Roboto";

  color: #fff;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5vw;
  letter-spacing: 0em;
  text-align: center;
}
.formLeft {
  margin-right: 7%;
}
.buttonRow {
  display: flex;
  justify-content: center;
}
.buttonRow button {
  cursor: pointer;
}
textarea::placeholder {
  margin-right: 15%;
}
@media (max-width: 720px) {
  .form {
    display: block;
    margin-bottom: 0;
  }
  .input {
    background: #d3cdcd;
    border-radius: 1.5vw;
    font-size: 3vw;
  }
  .formRight {
    margin-right: 5%;
    margin-left: 5%;
  }
  .formLeft {
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 8%;
  }
  .form button {
    font-size: 6vw;
    padding-bottom: 3%;
    padding-top: 3%;
  }
}
