.button {
  display: grid;
  grid-template-columns: 1fr;

  background-color: #ffffff;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  margin-top: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 3%;
}
.titleActive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
}
#iconDesktop {
  display: flex;
  height: 2.8vw;
  padding: 7px;
  border: 1px solid rgba(58, 36, 74, 0.61);
  box-sizing: border-box;
  border-radius: 15%;
}
#iconMobile {
  display: none;
  padding: 10%;
  border: 1px solid rgba(58, 36, 74, 0.61);
  box-sizing: border-box;
  border-radius: 15%;
}
.button p {
  color: #2a1a36;
  font-family: "Inria Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.titleActive h2 {
  color: #1e0f2a;
  font-family: "Inria Sans";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: bold;
  line-height: 2.6vw;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.title h2 {
  color: #1e0f2a;
  font-family: "Inria Sans";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: bold;
  line-height: 2.6vw;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.info {
  display: row;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-right: 8%;
}
.descricao {
  color: #2a1a36;
  font-family: "Inria Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.lista {
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3%;
  margin-left: 5%;
}
.lista li {
  color: #2a1a36;
  margin-top: 3%;
  font-family: "Inria Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.subtitulo {
  color: #2a1a36;
  font-family: "Inria Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.button:hover {
  background-color: #dddbdf;
}
.button:focus {
  background-color: #dddbdf;
  border-radius: 0px #ffff;
}
.button img {
  margin-right: 5%;
}
#text {
  display: none;
}
@media (max-width: 990px) {
  .button h2 {
    grid-template-columns: 0.4fr 0.7fr;
  }
}
@media (max-width: 720px) {
  .button {
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
  }
  .titleActive {
    display: flex;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .titleActive h2 {
    width: auto;
  }
  .button h2 {
    font-size: 4vw;
    grid-template-columns: 0.4fr 0.4fr;
  }
  #text {
    display: flex;
  }
  #iconDesktop {
    display: none;
  }
  #iconMobile {
    display: flex;
    height: 7vw;
    padding: 7px;
  }
  .info {
    padding-top: 3%;
    width: 90%;
  }
}
