.back {
 
    background: #605A66;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    justify-content: center;
}
.back h1 {
    color : rgba(196, 196, 196, 0.71);

    font-family: 'Inria Sans';
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 3vw;
    letter-spacing: 0em;
    text-align: right;

}
.back img {
    height : 2vw;

}