.container {
  display: grid;
  grid-template-columns: 4fr 4fr;
  height: 84vh;

  background: url("../../assets/Welcome/office.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* background-attachment: fixed; */
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(196, 196, 196, 0.71);
  /* padding-left : 9.5vh;
    padding-right: 7.5vh; */
}
.text h1 {
  /* margin-top: 25vh;  */
  width: 78%;
  /* min-height: 20px;
    max-height : 250px;
    height: 22%; */

  color: #2a1a36;
  font-family: "Inria Serif";
  font-size: 4.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 5vw;
  letter-spacing: 0em;
  text-align: left;
}
.text h3 {
  width: 78%;
  height: 22%;
  padding-top: 5px;

  font-family: "Inria Sans";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7vw;
  letter-spacing: 0em;
  text-align: left;
  color: #2a1a36;
}
@media (max-width: 1600px) {
  .container {
    min-height: 480px;
    max-height: 551px;
  }
}

@media (max-width: 960px) {
  .container {
    min-height: 300px;
    max-height: 500px;
  }
}

@media (max-width: 720px) {
  .container {
    min-height: 300px;
    max-height: 400px;
    height: 50vh;
  }

  .text h1 {
    font-size: 6vw;
    line-height: 6.5vw;
    padding-bottom: 3%;
    font-weight: bold;
  }
  .text h3 {
    font-size: 3vw;
    line-height: 3.3vw;
  }
  /* .text {
            padding-bottom : 70px;
        } */
}

@media (max-width: 480px) {
  .container {
    min-height: 180px;
    max-height: 300px;
    height: 30vh;
  }

  .text h1 {
    font-size: 6vw;
    line-height: 6.2vw;
  }
  .text h3 {
    font-size: 3.3vw;
    line-height: 3.3vw;
  }
}
