.container {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  height: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  background: #eae7e7;
  border-radius: 1vw;
  padding: 3%;
}
.container h1 {
  font-family: "Inria Sans";
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5vw;
  letter-spacing: 0em;
  text-align: left;

  padding-right: 2%;
  padding-left: 12%;
}
.button {
  justify-content: center;
  text-align: center;
  padding-top: 12%;
}
.button a {
  background: #e2c033;
  border-radius: 1vw;
  padding-top: 6%;
  padding-bottom: 6%;
  padding-left: 8%;
  padding-right: 8%;

  font-family: "Roboto";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}
@media (max-width: 720px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.7fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  .container h1 {
    font-size: 6.8vw;
    line-height: 7.8vw;
    padding-top: 8%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .button {
    padding: 5% 8%;
  }
  .button a {
    font-size: 4.8vw;
    border-radius: 15px;
  }
}
