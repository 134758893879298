.footer {
  position: relative;
  margin-bottom: -1%;
  background: #1e0f2a;
  padding-top: 3.5%;
  padding-bottom: 3%;
}

.title {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}
.title h1 {
  color: #ffff;
  font-family: "Catamaran";
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: center;
}
.data {
  padding-top: 2%;
  padding-left: 25%;
  justify-items: center;
}
.data img {
  height: 3vw;
}
.infodata {
  display: flex;
  align-items: center;
  padding-top: 0.8%;
}
.infodata h2 {
  color: #ffffff;

  font-family: "Roboto";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 2%;
}

@media (max-width: 720px) {
  .title {
    display: block;
    margin-bottom: 5%;
  }
  .data {
    display: none;
  }
  .title h1 {
    font-size: 8vw;
    line-height: 9vw;
    text-align: left;
    margin-left: 5%;
  }
}
