#firstSector {
  display: grid;
  grid-template-columns: 0.055fr 1fr;
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 27%;
}
#firstSector h1 {
  color: #ffff;
  font-family: "Roboto";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: left;
}
#firstCard {
  padding-top: 3%;
  padding-bottom: 2%;
  padding-left: 3%;
  padding-right: 30%;
}
#border {
  background-color: #e2c033;
  border-radius: 1vw;
}
@media (max-width: 720px) {
  #firstSector {
    margin-left: 10%;
  }
  #firstCard {
    padding-top: 3%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }
  #firstSector h1 {
    font-size: 4.8vw;
    line-height: 5.8vw;
  }
}
