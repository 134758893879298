.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contentLeft {
  text-align: center;
}
.title {
  color: #c4c4c4;
  font-family: "Inria Sans";
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: center;
}
.contentLeft h3 {
  color: #c4c4c4;
  font-family: "Inria Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.6vw;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 5%;
  padding-right: 10%;
  padding-left: 16%;
  padding-top: 5%;
}
.container a {
  background-color: #e2c033;
  border-radius: 1vw;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;

  font-family: "Roboto";
  font-size: 2vw;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
}
#buttonDesktop {
  display: inline-flex;
  margin-top: 3%;
  margin-bottom: 15%;
  margin-right: 18%;
  padding-left: 10%;
  padding-right: 10%;
}
#buttonMobile {
  display: none;
}

.contentRight {
  margin-left: 15%;
  padding-top: 20%;
  margin-left: 15%;
  padding-bottom: 15%;
}

.sectors {
  display: grid;
  grid-template-columns: 0.055fr 1fr;
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 18%;
  background: #eae7e7;
  border-radius: 0.7vw;
}
.sectors h1 {
  color: #1e0f2a;
  font-family: "Roboto";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5vw;
  letter-spacing: 0em;
  text-align: left;
}
#border {
  background-color: #e2c033;
  border-radius: 0.5vw;
}

.sector {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 3%;
}
@media (max-width: 720px) {
  .container {
    display: block;
  }
  .title {
    font-size: 7vw;
    color: #ffffff;
    text-align: left;
    padding-left: 10%;
    padding-top: 0;
    padding-bottom: 5%;
  }
  .contentLeft h3 {
    color: #ffffff;
    padding-bottom: 8%;
    padding-top: 5%;
    padding-right: 20%;
    padding-left: 10%;
    font-size: 4.2vw;
    line-height: 5.5vw;
    font-weight: 300;
  }
  .contentRight {
    padding-top: 1%;
    margin-left: 0;
    margin-left: 0;
    padding-bottom: 0;
  }
  .sectors {
    margin-left: 10%;
    margin-right: 15%;
    border-radius: 1vw;
  }
  .sectors h1 {
    font-size: 4.5vw;
  }
  #buttonDesktop {
    display: none;
  }
  #buttonMobile {
    display: inline-block;
    font-size: 4.5vw;
    margin-left: 20%;
    padding-right: 10%;
    padding-left: 10%;
    margin-bottom: 10%;
    margin-top: 10%;
  }
  #border {
    border-radius: 1vw;
  }
}

@media (max-width: 480px) {
  #border {
    border-radius: 0.8vw;
  }
}
