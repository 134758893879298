.container {
  display: block;
  background-color: #1e0f2a;
  /* margin-top: 5%;
  margin-bottom: 5%; */
}
.title {
  color: #ffffff;
  padding-top: 5%;
  padding-bottom: 7%;

  font-family: "Inria Sans";
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8%;
}

.bold {
  color: #ffffff;
  font-family: "Inria Sans";
  font-size: 4.5vw;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: left;
}
.cardsMobile {
  display: none;
}

.carousel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 1%;
  margin-left: 1%;
  padding-bottom: 10%;
}

@media (max-width: 720px) {
  .carousel {
    display: none;
  }
  .cardsMobile {
    display: block;
    padding-bottom: 10%;
  }
  .title {
    font-size: 7vw;
  }
  .bold {
    font-size: 7vw;
  }
}
