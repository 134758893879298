.header {
    display : grid;
    grid-template-columns : 1fr 1fr;
    width: 100%;
    background-color : #E2C033;
    

}
.headerRight {
    display : grid;
    grid-template-columns : 1.3fr 1.5fr 1fr 1.3fr;
    background-color: #1E0F2A;
    justify-items: center;
    align-items: center;

    

}
.headerRight a {
    color : white;

    text-decoration:none;
    font-family: 'Inria Sans';
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: #E5E5E5;


}
.contact {
    display : grid;
    grid-template-columns: .1fr 1fr;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding-right : 5%;
    padding-left : 5%;
    padding-top : 5%;
    padding-bottom : 5%;
    margin-right : 5%;
    align-items: center;

}

.contact img {
    height : 4vw;
    padding-left : 2%;
    padding-right : 2%;
    padding-top : 10%;

}
.contact h1 {
    color : #E5E5E5;
    font-family: 'Inria Sans';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8vw;
    letter-spacing: 0em;
    text-align: center;

}
.logo {
    padding-top : 15px;
    padding-left : 8px;
    background-color: #E2C033;
    padding-bottom : 10px;
}
#sidebarMenu {
    display : none;
}
.sidebar {
    display : none;
    margin-right : 20%;
    margin-top : 10%;

}
.sidebar button {
    background : none;
    border : 0;
    outline : none;
}
.sidebar img {
    height : 11vw;
}
#logoDesktop {
    display : flex;
    height : 6vw;
    margin-top : 1%;
    margin-bottom : 1%;

}
#logoMobile {
    display: none;
}
#logoTablet {
    display : none;
}
#iconTablet{
    display : none;
}
#iconDesktop {
    display:flex;
}

@media(max-width: 959px) {
    .headerRight a {
        font-size : 1.4vw;;
    }
    .logo img {
        height: 10vw;
    }
    
    #iconTablet{
        display : flex;
    }
    #iconDesktop {
        display:none;
    }

    }

@media(max-width: 720px) {
    .headerRight a {
        font-size : 11px;
         }
    
    #sidebarMenu {
        display : block;
    }
    #logoDesktop {
            display: none;}
    #logoMobile {
        display : flex;
    }
    #logoTablet {
        display: none;
    }
    .headerRight{
        display : none;
    }
    .header {
        grid-template-columns: 1fr .1fr;
    }
    .sidebar {
        display : flex;
        padding-right: 10px;
        justify-content: center;
        align-items: center;
    }
    .logo {

        padding-left: 20px;
        justify-content: center;
        align-items: center;

    }
    .logo img {
        height : 12vw;
    }

}