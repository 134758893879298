.container {
  display: grid;
  grid-template-columns: 4fr 4fr;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  margin-top: 2%;

  background-color: #ffff;
}
.card {
  width: 100%;
  padding-bottom: 5%;
}
.content {
  display: block;
  padding-bottom: 15%;
}
.content h1 {
  margin-top: 3%;
  text-align: left;

  padding-right: 15%;
  padding-left: 15%;
  padding-bottom: 7%;

  color: #2a1a36;
  font-family: "Inria Sans", sans-serif;
  font-size: 3.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: 3.6vw;
  letter-spacing: 0em;
}

.button {
  display: grid;
  padding-right: 15%;
  padding-left: 15%;
}

@media (max-width: 720px) {
  .container {
    display: block;
    margin-top: 2%;
    height: auto;
  }
  .content {
    padding-bottom: 5%;
  }
  .button {
    padding-right: 3vh;
    padding-left: 3vh;
  }
  .content h1 {
    text-align: center;
    font-size: 7vw;
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 5%;
    margin-top: 0;
  }
}
