.midias {
  margin-left: 10%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.midias div {
  display: inline-block;
}

.midias h1 {
  color: #a7a5a9;

  font-family: "Inria Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: left;
  width: 30%;

  margin-bottom: 2%;
}
.midias img {
  width: 4vw;
  padding-right: 15%;
  padding-left: 10%;
  margin-top: -30%;
  margin-left: -15%;
  cursor: pointer;
}

#logoMobile {
  display: none;
}
@media (max-width: 720px) {
  .midias h1 {
    font-size: 7vw;
    margin-bottom: 10%;
    width: auto;
  }
  .midias img {
    height: 9vw;
    width: 14vw;
  }
}
